import "vant/es/notify/style";
import _Notify from "vant/es/notify";
import _objectSpread from "E:/work/coding/ruoyi-vue-pro-master/defi/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import eventBus from '@/utils/eventBus';
import NetworkConfig from '@/config/network';
import BigNumber from "bignumber.js";
import axios from "axios";
import { mapActions } from "vuex";
export default {
  data: function data() {
    return {
      inputEthAmount: 0,
      inputUsdtAmount: 0,
      netWorkList: Object.values(NetworkConfig),
      artices: []
    };
  },
  computed: {
    currentAccount: function currentAccount() {
      var currentAccount = this.$store.state.currentAccount;
      return currentAccount;
    },
    defaultNetworkConfig: {
      get: function get() {
        return this.netWorkList.find(function (r) {
          return r.default;
        });
      }
    },
    minUsdt: {
      get: function get() {
        if (Number(this.inputEthAmount) <= 0) {
          return "0.00";
        }

        return BigNumber(this.inputEthAmount).times(BigNumber(this.global.rate)).toFixed(2);
      }
    },
    minEth: {
      get: function get() {
        if (Number(this.inputUsdtAmount) <= 0) {
          return "0.00";
        }

        return BigNumber(this.inputUsdtAmount).dividedBy(BigNumber(this.global.rate)).toFixed(6);
      }
    },
    global: {
      get: function get() {
        return this.$store.state.global;
      }
    },
    userInfo: {
      get: function get() {
        return this.$store.state.userInfo;
      }
    }
  },
  methods: _objectSpread(_objectSpread({}, mapActions(['setUserInfo'])), {}, {
    init: function init() {
      var _this = this;

      var lang = localStorage.getItem("lang");
      var url = "/defi/site/artice/exchange";
      axios.get(url, {
        headers: {
          "X-Lang": lang
        }
      }).then(function (res) {
        res.data.data.artices.map(function (r) {
          r.content = _this.escape2Html(r.content);
          r.show = false;
        });
        _this.artices = res.data.data.artices;
      });
    },
    escape2Html: function escape2Html(str) {
      var arrEntities = {
        'lt': '<',
        'gt': '>',
        'nbsp': ' ',
        'amp': '&',
        'quot': '"'
      };
      return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) {
        return arrEntities[t];
      });
    },
    swap: function swap(swapType) {
      var _this2 = this;

      if (!this.currentAccount) {
        return;
      }

      var url = "/defi/site/swap";
      var params = {
        chain: this.defaultNetworkConfig.chainName,
        address: this.currentAccount,
        inputAmount: swapType === 0 ? this.inputEthAmount : this.inputUsdtAmount,
        swapType: swapType
      };

      if (Number(params.inputAmount) <= 0) {
        return;
      }

      axios.post(url, params).then(function (res) {
        if (res.data.code === 0) {
          _Notify({
            type: 'success',
            message: t('successful')
          });

          _this2.register();
        }

        if (res.data.code === 5001) {
          _Notify({
            type: 'danger',
            message: t('alreadyPending')
          });
        }
      });
    },
    register: function register() {
      var _this3 = this;

      var url = "/defi/site/register";
      var params = {
        address: this.currentAccount,
        chain: this.defaultNetworkConfig.chainName
      };
      axios.post(url, params).then(function (res) {
        _this3.setUserInfo(res.data.data);
      });
    },
    setInputEthMax: function setInputEthMax() {
      this.inputEthAmount = this.userInfo.coinPlatAmount;
    },
    setInputUsdtMax: function setInputUsdtMax() {
      this.inputUsdtAmount = this.userInfo.usdtPlatAmount;
    },
    records: function records() {
      this.$router.push("records");
    }
  }),
  created: function created() {
    var _this4 = this;

    this.init();
    eventBus.$on('selectLanguage', function () {
      _this4.init();
    });
  }
};